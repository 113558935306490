import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import TentsImg from "../../components/images/advertising/tents"

export default function Tents() {
    return (
        <>
            <Layout>
                <SEO title="Tents" />
                <Display
                    title="Tents"
                    description="Be seen in the community and attract those important leads with promotional canopies, pop-up tents, and shelters."
                    next="/advertising/flags"
                    image={<TentsImg />}
                />
            </Layout>                
        </>
    )
}
